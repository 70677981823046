import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./language/i18n";
import { Provider } from "react-redux";
import { persistor, store } from "./reducers/store";
import { PersistGate } from "redux-persist/integration/react";
import { GeoProvider } from "./components/selectRef/hook/useGeofenceContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { MapContextProvider } from "./pages/map/useMapContext";
import SocketProvider from "./socket-client/SocketContext";
import { VideoProvider } from "./components/selectRef/hook/useVideoContext";

//import TestMap from './test/TestMap';

console.log("index testing");

declare global {
  interface Window {
    s: any;
  }
}

const client = new QueryClient();
export const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SocketProvider>
            <MapContextProvider>
              <GeoProvider>
                <VideoProvider>
                  <App />
                </VideoProvider>
              </GeoProvider>
            </MapContextProvider>
          </SocketProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// root.render(
//   // <Demo/>
//   // <DrawPolygonExample/>
//  // <NewDraw />
// //<MapDraw/>
// //<Map/>
// //<MapWithPolygonEditor/>
// //<ShowMap/>
// <NewPam/>
// //<SelectPage/>
// //<Uploads/>
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
