import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Actions = {
  lat: number;
  lng: number;
  id: string;
  icon: string;
  desc?: any;
};
type POIInitState = {
  ids: string[];
  activePois: any[];
};
const initialState: POIInitState = {
  ids: [],
  activePois: [],
};

const poiSlice = createSlice({
  name: 'poi',
  initialState: initialState,
  reducers: {
    handleActivePoi: (state, actions: PayloadAction<Actions>) => {
      let id = state.ids.includes(actions.payload.id);
      if (id) {
        state.activePois = state.activePois?.filter(
          (item) => item?.id !== actions.payload.id
        );
        state.ids = state.ids.filter((p) => p !== actions.payload.id);
      } else {
        state.activePois.push(actions.payload);
        state.ids.push(actions.payload.id);
      }
    },

    deletePoi: (state, actions) => {
      let id = state.ids.includes(actions.payload.id);
      if (id) {
        state.activePois = state.activePois?.filter(
          (item) => item?.id !== actions.payload.id
        );
        state.ids = state.ids.filter((p) => p !== actions.payload.id);
      }
    },
    resetPois: (state) => {
      state.ids = initialState.ids;
      state.activePois = initialState.activePois;
    },
  },
});

export const { handleActivePoi, deletePoi, resetPois } = poiSlice.actions;
export default poiSlice.reducer;
