import React, { createContext, useContext, useState } from "react";

type VideoLeftCtxTypes = {
  columns: number;
  setColumns: React.Dispatch<React.SetStateAction<number>>;
};

const defaultContextValue: VideoLeftCtxTypes = {
  columns: 1,
  setColumns: () => {},
};

export const VideoLeftCtx =
  createContext<VideoLeftCtxTypes>(defaultContextValue);
export const useDeviceVideo = () => {
  return useContext(VideoLeftCtx);
};
export const VideoProvider = ({ children }: { children: React.ReactNode }) => {
  const [columns, setColumns] = useState<number>(1);

  return (
    <VideoLeftCtx.Provider value={{ columns, setColumns }}>
      {children}
    </VideoLeftCtx.Provider>
  );
};
